<template>
  <div class="ev2-box resumo">
    <div class="header">
      <div class="hcol1">
        <div class="title">Resumo Completo dos Registros</div>
        <div class="subtitle">Informações sobre a base cadastral</div>
      </div>
      <div class="hcol2">
        <i class="fal fa-refresh cursor-pointer" @click="load" v-if="!loading" />
        <sl-loading v-else class="size-18" content="" />
      </div>
    </div>
    <div class="body m-t">
      <div class="task-count">
        <div class="count">
          <div class="lbl">Pendentes:</div>
          <div class="val-container">
            <div class="value actives">{{ stats.pendente }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Para análise:</div>
          <div class="val-container">
            <div class="value pending">{{ stats.analise }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Esperando documentos:</div>
          <div class="val-container">
            <div class="value pending">{{ stats.documentacao }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Em pagamento:</div>
          <div class="val-container">
            <div class="value started">{{ stats.pagamento }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Aguardando Registro:</div>
          <div class="val-container">
            <div class="value homolog">{{ stats.registro }}</div>
          </div>
        </div>
        <div class="hr" />
        <div class="count m-t-lg">
          <div class="lbl">Ativos:</div>
          <div class="val-container">
            <div class="value abertas">{{ stats.ativos }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Bloqueados:</div>
          <div class="val-container">
            <div class="value bg-negative text-white">{{ stats.bloqueados }}</div>
          </div>
        </div>
        <div class="count">
          <div class="lbl">Expirados:</div>
          <div class="val-container">
            <div class="value fechadas">{{ stats.expirados }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SlLoading from "components/layout/components/Loading.vue";
import {stats} from "@/domain/fenaju/services";

export default {
  name: "Resumo",
  components: {SlLoading},
  data () {
    return {
      loading: true,
      stats: {
        total: null,
        pendente: null,
        analise: null,
        documentacao: null,
        pagamento: null,
        registro: null,
        ativos: null,
        bloqueados: null,
        expirados: null
      }
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      this.loading = true
      stats()
          .then(response => {
            this.stats = Object.assign(this.stats, response.data)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    }
  }
}
</script>
